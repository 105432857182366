import React from 'react';
import { useStaticQuery, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import BackgroundSection from './BackgroundSection';
import Image from './Image.js';
import mq from '../styles/media-queries';
import { H1Box } from '../styles/common/Element';
import { Container } from '../styles/common/Layout';

const SectionStyles = styled.section`
  background-color: var(--black);
  width: 100%;
  min-height: 200px;
  padding: 6em 0px;
`;

const PartnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Partner = styled.a`
  flex: 1 0 50%;
  ${mq.md} {
    flex: 1 0 20%;
  }
  align-self: center;
`;

export default function SectionPartner() {
  const data = useStaticQuery(graphql`
    query PartnerQuery {
      partner: allSanityPartner {
        nodes {
          _id
          partnerName
          partnerUrl
          partnerLogo {
            asset {
              fluid {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const partners = data.partner.nodes;
  return (
    <>
      <SectionStyles id="partner">
        <Container>
          <H1Box>Wir bedanken uns für die Unterstützung bei...</H1Box>
          {partners && (
            <PartnerWrapper>
              {partners.map((partner) => (
                <Partner key={partner._id} href={partner.partnerUrl}>
                  <Image
                    loading="lazy"
                    image={partner.partnerLogo.asset.fluid}
                    alt={partner.partnerName}
                  />
                </Partner>
              ))}
            </PartnerWrapper>
          )}
        </Container>
      </SectionStyles>
    </>
  );
}
