import { createGlobalStyle } from 'styled-components';
import mq from './media-queries';

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #000000;
    --yellow: #ffc600;
    --white: #fff;
    --grey: #efefef;
    --darkgrey: #555555;
    --field__background: #f5f5f5;
    --field__background--hover: #e9e9e9;
    --color__accent: #6200ee;
    --input__border: rgba(0, 0, 0, 0.42);
    --input__border--hover: rgba(0, 0, 0, 0.62);
    --label__color: #222;
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
  html {
    font-size: 10px;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }
  button {
    background: inherit;
    border: none;
    color: var(--white);
  }
  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--white) var(--black);
  }
  body::-webkit-scrollbar-track {
    background: var(--black);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--white);
    border-radius: 6px;
    border: 3px solid var(--black);
  }
  body {
    background-color: ${(props) => (props.bgColor ? props.bgColor : 'black')};
    color: ${(props) => (props.bgColor == 'white' ? 'black' : 'white')};
  }
  body.no-js .fadeIn {
  opacity: 1;
}
  body.no-js .slideLeft {
    opacity: 1;
    transform: none;
  }
  body.no-js .slideUp {
    opacity: 1;
    transform: none;
  }
  body.no-js .reveal {
    opacity: 1 !important;
    transform: none !important;
  }

  hr {
    border: 0;
    height: 8px;
  }
  img {
    max-width: 100%;
  }
  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: 0.25s;

    &:hover,
    &:focus {
      color:var(--black);
      box-shadow: inset 0 -3.25em 0 0 var(--white);
    }
  }
  .btn-lg {
      padding: 10px 16px;
      font-size: 1.6rem;
      line-height: 1.3333333;
      text-transform: uppercase;
      border-radius: 6px;

    ${mq.sm} {
      max-width: 1366px;
    }
  }
  .btn-dark {
    font-family: 'Lato-Bold';
    background: transparent;
    color: var(--white);
    border-width: 2px;
    border-color: var(--white);
    border-radius:0;
  }

  .btn-black {
    font-family: 'Lato-Bold';
    background: transparent;
    color: var(--black);
    border-width: 2px;
    border-color: var(--black);
    border-radius:0;

    &:hover,
    &:focus {
      color:var(--white);
      box-shadow: inset 0 -3.25em 0 0 var(--black);
    }
  }

  .fadeIn {
    transition: opacity 1s ease-in-out;
    opacity: 0;
  }
  .fadeIn.enter {
    transition: opacity 1s ease-in-out;
    opacity: 1;
  }
  .slideLeft {
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    opacity: 0;
    transform: translateX(100%);
  }
  .slideLeft.enter {
    transition: opacity 1s ease-in-out, transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 1;
    transform: translateX(0);
  }
  .slideUp {
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    opacity: 0;
    transform: translateY(100%);
  }
  .slideUp.enter {
    transition: opacity 1s ease-in-out, transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 1;
    transform: translateY(0);
  }
  @media (print), (prefers-reduced-motion: reduce) {
    .fadeIn,
    .slideLeft,
    .slideUp {
      transition: none;
      opacity: 1;
      transform: none;
    }
  }
`;

export default GlobalStyles;
