import React from 'react';
import 'normalize.css';
import styled from 'styled-components';
import ReactBreakpoints from 'react-breakpoints';
import Footer from './Footer';
import Header from './Header';
import SectionDonation from './SectionDonation';
import SectionPartner from './SectionPartner';
import SectionVideo from './SectionVideo';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}

const breakpoints = {
  mobile: 500,
  mobileLandscape: 768,
  tablet: 1024,
  tabletLandscape: 1024,
  desktop: 1366,
  desktopLarge: 1920,
  desktopWide: 2560,
};

export default function Layout({ children, ...props }) {
  const { bgColor } = props;
  return (
    <>
      <ReactBreakpoints breakpoints={breakpoints}>
        <GlobalStyles bgColor={bgColor} />
        <Typography />
        <Header />
        {children}
        <SectionVideo />
        <SectionPartner />
        <Footer />
      </ReactBreakpoints>
    </>
  );
}
