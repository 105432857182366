import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FaInstagram, FaFacebookSquare, FaBars } from 'react-icons/fa';
import Logo from './Logo';
import { Container, Row } from '../../styles/common/Layout';
import mq from '../../styles/media-queries';

const NavWrapper = styled.div`
  background-color: var(--black);
  width: 100%;

  &.is-fixed {
    position: fixed;
    top: 0;
    z-index: 101;
  }
`;

const NavBrand = styled.div`
  margin-left: 20px;
  align-self: center;

  ${mq.sm} {
    margin-left: 0px;
    width: 140px;
    margin-right: 20px;
    max-width: inherit;
  }
`;
const NavBar = styled.div`
  width: 100%;
`;

const MainNav = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: center;
  list-style: none;
  transition: height 200ms 0ms, opacity 200ms 200ms linear;
  flex-direction: column;

  &.hidden {
    opacity: 0;
    height: 0px;
    transition: height 200ms 200ms, opacity 200ms 0ms;
    display: none;
  }
  ${mq.md} {
    flex-direction: row;
    justify-content: space-around;

    &.hidden {
      opacity: 1;
      height: auto;
      transition: height 200ms 200ms, opacity 200ms 0ms;
      display: flex;
    }
  }
`;
const MainNavElement = styled.li`
  flex: 1 0 15%;
  margin-bottom: 1rem;

  ${mq.md} {
    margin-bottom: 0rem;
  }

  &:not(.social):hover {
    a {
      color: black;
    }
    background-color: var(--white);
  }

  &.social {
    flex: 0 1 15%;
    text-align: right;
    a:first-child {
      margin-right: 1rem;
    }
  }
`;

const NavStyles = styled(Container)`
  transition: top 1s linear;
`;

const NavRow = styled(Row)`
  flex-wrap: wrap;
  justify-content: space-between;

  ${mq.md} {
    flex-wrap: nowrap;
  }
`;

const LinkStyles = styled(Link)`
  position: relative;
  text-transform: uppercase;
  font-size: 1.5rem;
  text-decoration: none;
  padding: 0em;
  transition: font-size 0.25s linear;
  color: var(--white);
  -webkit-font-smoothing: subpixel-antialiased;

  display: block;
  padding: 1rem 0;

  ${mq.md} {
    padding: 4rem 0;
  }
`;

const A = styled.a`
  position: relative;
  text-transform: uppercase;
  font-size: 1.5rem;
  text-decoration: none;
  padding: 0em;
  transition: font-size 0.25s linear;
  color: var(--white);
  -webkit-font-smoothing: subpixel-antialiased;

  display: block;
  padding: 1rem 0;

  ${mq.md} {
    padding: 4rem 0;
  }
`;

const LinkLogo = styled(Link)`
  position: relative;
  text-transform: uppercase;
  font-size: 1.7rem;
  text-decoration: none;
  padding: 0em;
  transition: font-size 0.25s linear;
  color: var(--white);
  -webkit-font-smoothing: subpixel-antialiased;

  display: block;
  padding: 0rem 0;
`;

const ToggleButton = styled.button`
  visibility: visible;
  opacity: 1;
  display: block;
  width: 25%;

  background-color: transparent;
  color: var(--white);
  border: none;
  svg {
    color: var(--white);
  }
  ${mq.md} {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
`;

export default function Nav({ position }) {
  const [isExpanded, toggleExpansion] = useState(false);

  const closeMenu = () => {
    toggleExpansion(false);
  };

  return (
    <NavWrapper className={position.y > 45 ? 'is-fixed' : ''}>
      <NavStyles>
        <NavRow>
          <NavBrand>
            <LinkLogo className="logo" to="/">
              <Logo />
            </LinkLogo>
          </NavBrand>
          <ToggleButton
            data-target="navigation"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <FaBars size={32} />
          </ToggleButton>
          <NavBar role="navigation" aria-label="Main">
            <MainNav className={`${isExpanded ? `block` : `hidden`}`}>
              <MainNavElement>
                <LinkStyles
                  to="/#infos"
                  activeClassName="active"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  Infos
                </LinkStyles>
              </MainNavElement>
              <MainNavElement>
                <LinkStyles
                  to="/kulturgesichter"
                  activeClassName="active"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  Gesichter
                </LinkStyles>
              </MainNavElement>
              <MainNavElement>
                <LinkStyles
                  to="/#partner"
                  activeClassName="active"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  Partner
                </LinkStyles>
              </MainNavElement>
              <MainNavElement>
                <LinkStyles
                  to="/presse"
                  activeClassName="active"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  Presse
                </LinkStyles>
              </MainNavElement>
              <MainNavElement>
                <A
                  rel="noopener"
                  target="_blank"
                  href="https://support.kulturgesichternrw.de/"
                >
                  Support Us
                </A>
              </MainNavElement>
              <MainNavElement className="social">
                <a
                  rel="noreferrer"
                  href="https://www.facebook.com/kulturgesichterNRW.de"
                >
                  <FaFacebookSquare
                    aria-label="Go to Kulturgesichter Facebook"
                    size={32}
                  />
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.instagram.com/kulturgesichternrw/"
                >
                  <FaInstagram
                    aria-label="Go to Kulturgesichter Instagram"
                    size={32}
                  />
                </a>
              </MainNavElement>
            </MainNav>
          </NavBar>
        </NavRow>
      </NavStyles>
    </NavWrapper>
  );
}
