import React, { useState, useEffect } from 'react';
import 'normalize.css';
import styled from 'styled-components';
import { useScrollData } from 'scroll-data-hook';
import { useTransition } from 'react-spring';
import Nav from './Nav';
import TopBar from './TopBar';
import mq from '../../styles/media-queries';
import ScrollToTop from '../UI/scrollToTop';

const HeaderStyles = styled.header`
  background-color: var(--white);
  width: 100%;
`;

const HeaderPlaceholderStyles = styled.div`
  display: none;

  ${mq.sm} {
    height: 144px;
    width: 100%;
    display: none;
  }
`;

export default function Header() {
  const { position } = useScrollData();

  // State to show or hide scroll to top component, gets trigged based on the scroll link component
  const [showScrollTop, setShowScrollTop] = useState(false);
  // Animation
  const ScrollTopTransition = useTransition(showScrollTop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  useEffect(() => {
    if (position.y > 90) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  }, [position]);
  return (
    <>
      <HeaderStyles>
        <Nav position={position} />
        {ScrollTopTransition.map(
          ({ item, key, props }) =>
            item && <ScrollToTop style={props} key={key} />
        )}
      </HeaderStyles>
      <HeaderPlaceholderStyles
        style={{ display: position.y > 45 ? 'block' : 'none' }}
      />
    </>
  );
}
