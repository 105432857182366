import React from 'react';
import styled from 'styled-components';
import Player from 'react-lazy-youtube';

const VideoWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;

  &:has(+ iframe):after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = ({
  videoSrcId,
  videoWidth,
  videoHeight,
  videoSrcImage,
  ...props
}) => (
  <VideoWrapper>
    {typeof window !== 'undefined' && (
      <Player
        id={videoSrcId}
        imageSize={videoSrcImage || 'maxresdefault'}
        styles={{
          width: videoWidth,
          height: videoHeight,
        }}
      />
    )}
  </VideoWrapper>
);
export default Video;
