import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import mq from '../styles/media-queries';

const FooterStyles = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
  min-height: 200px;
  color: var(--black);
  background-color: var(--white);

  small {
    font-size: 1.2rem;
    a {
      color: black;
    }
  }
`;

const NavStyles = styled.nav`
  margin-bottom: 3rem;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    list-style: none;
    flex-direction: column;
    ${mq.sm} {
      flex-direction: row;
    }
  }
  a {
    position: relative;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.5rem;
    color: var(--black);
    text-decoration: none;
    padding: 1em;
    transition: all 0.5s;
    &:before {
      transition: all 0.5s;
    }
    &:after {
      transition: all 0.5s;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0%;
      content: '.';
      color: transparent;
      background: #aaa;
      height: 1px;
    }
    &:not(.logo):hover:after {
      width: 100%;
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <NavStyles>
        <ul>
          <li>
            <Link to="/presse">Presse</Link>
          </li>
          <li>
            <Link to="/impressum">Impressum</Link>
          </li>
          <li>
            <Link to="/datenschutz">Datenschutzerklärung</Link>
          </li>
        </ul>
      </NavStyles>
      <p className="center">
        &copy; Kulturgesichter NRW e.V. (In Gr&uuml;ndung){' '}
        {new Date().getFullYear()}.<br />
        <small>
          made with ❤️ by{' '}
          <a href="https://www.marek-maras.de">www.marek-maras.de</a>
        </small>
      </p>
    </FooterStyles>
  );
}
